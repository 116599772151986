import React from "react";
import "./terms.css";

function Termsconditiion() {
  return (
    <div className="termcontainer">
      <h1 className="heading">Terms and Conditions</h1>
      <div className="termcontent">
        <p>
          Please carefully read the following terms and conditions before using
           swastikgift.online. By accessing or using our website, you agree to be
          bound by these terms and conditions. If you do not agree with any part
          of these terms and conditions, please refrain from using our services.
        </p>
        <p>
          <b>1. Payment, Delivery, and Returns</b>
          <ul>
            <li>
              <b>Returns Policy:</b>
              <br />
              swastikgift.online does not accept returns of items sold under
              promotion or stock clearance, except if they arrive in a damaged
              condition or are defective. The product description page will
              clearly state when items are non-returnable.
            </li>
          </ul>
        </p>
        <p>
          <b>2. Acceptance of Terms and Conditions</b>
          <br />
          By visiting swastikgift.online, you acknowledge and accept these terms
          andconditions. Furthermore, when utilizing any current or future
          swastikgift.online service (e.g., Your Account/Profile, Gift
          Certificates, Your Reminder Service, etc.) or engaging with any
          business affiliated with swastikgift.online, you are subject to these
          guidelines and conditions.
        </p>
        <p>
          <b>3. Agreement Superseding Previous Agreements</b>
          <br />
          These terms and conditions supersede all previous representations,
          understandings, or agreements and shall prevail, regardless of any
          variance with any other terms in any order placed or service provided
          by swastikgift.online. By using swastikgift.online's shopping
          services, you agree to abide by these terms and conditions.
        </p>
        <p>
          <b>4. Applicable Law and Jurisdiction</b>
          <br />
          This site is created and controlled by swastikgift.online. The laws of
          India apply, and courts in Delhi/New Delhi shall have jurisdiction
          over all the terms, conditions, and disclaimers. swastikgift.online
          reserves the right to modify the website, terms, conditions, and
          disclaimers without prior notice to customers/users.
        </p>
        <p>
          <b>5. Definitions</b>
          <br />
          <ul>
            <li>
              {" "}
              <b>Agreement:</b> <br />
              Refers to the terms and conditions detailed herein, including all
              schedules, appendices, annexures, and privacy policy, and
              encompasses references to this agreement as amended or replaced
              over time.{" "}
            </li>
            <li>
              {" "}
              <b>swastikgift.online / Swastik Gift /"website":</b> <br />{" "}
              Denotes the online shopping platform and services offered by it
              and its affiliates, owned and operated by Firki Wholesale Private
              Limited, providing a venue/platform for users to purchase products
              listed on swastikgift.online.{" "}
            </li>
            <li>
              {" "}
              <b>Vendor / Seller /Affiliates:</b> <br />
              Signifies individuals or legal entities offering products for sale
              on the SwastikGift platform/website.{" "}
            </li>
            <li>
              {" "}
              <b>Product/Products:</b> <br /> Includes any goods, merchandise,
              products, services, offers, display items showcased on
              SwastikGift, along with related descriptions, information,
              procedures, warranties, and delivery schedules.
            </li>
          </ul>
        </p>
        <p>
          <b>6. Electronic Communication</b> <br /> When you visit SwastikGift
          or communicate with us through e-mail or feedback, you agree to
          receive electronic communications from us. We may communicate with you
          via e-mail or by posting notices on the website. You acknowledge that
          all agreements, notices, disclosures, and other communications
          provided electronically satisfy any legal requirement for written
          communication.
        </p>
        <p>
          <b>7. Disclaimer of Warranties & Limitation of Liability</b> <br />
          SwastikGift provides this service for your convenience. We expressly
          disclaim any claim or liability arising from the uploading of obscene,
          vulgar, or pornographic content or the alteration of images in an
          obscene or harmful manner. SwastikGift also disclaims all warranties,
          whether express or implied, including but not limited to
          merchantability and fitness for a particular purpose. We do not
          guarantee that this site, its servers, or e-mails from SwastikGift are
          free of viruses or other harmful components. By using
          swastikgift.online, you agree to abide by these terms and conditions.
          If you have any questions or concerns, please contact us before using
          our services.
        </p>
        <p>
          <b>8. User Responsibilities</b>
          <br />
          <ul>
            <li>
              <b>a. Account Security:</b>
              <br /> You are responsible for maintaining the security of your
              account credentials and any activities conducted under your
              account. Please notify us immediately if you suspect unauthorized
              access to your account.
            </li>
            <li>
              <b>b. Accurate Information:</b>
              <br /> When creating an account or placing orders, ensure that the
              information you provide is accurate, complete, and up-to-date.
              Inaccurate information may lead to issues with delivery and
              communication.
            </li>
          </ul>
        </p>
        <p>
          <b>9. Ordering Process</b>
          <br />
          <ul>
            <li>
              {" "}
              <b>a. Invitation to Offer:</b>
              <br /> All products and information displayed on
              swastikgift.online constitute an "invitation to offer." Your order
              for purchase constitutes your "offer," which is subject to the
              terms and conditions outlined herein. swastikgift.online reserves
              the right to accept or reject your offer.
            </li>
          </ul>
        </p>
        <p>
          <b>10. Intellectual Property</b>
          <br />{" "}
          <ul>
            <li>
              <b>a. Content Ownership:</b>
              <br /> All content on swastikgift.online, including but not
              limited to text, graphics, logos, images, audio clips, and
              software, is the property of swastikgift.online and is protected
              by copyright and other intellectual property laws. You may not
              reproduce, distribute, or use our content without our explicit
              consent.{" "}
            </li>
          </ul>
        </p>
        <p>
          <b>11. Modification of Services</b>
          <br />{" "}
          <ul>
            <li>
              <b>a. Right to Change:</b> <br />
              swastikgift.online reserves the right to modify, suspend,
              ordiscontinue any part of its services, including product
              listings,without prior notice. We are not liable for any
              inconvenience causedby such modifications.
            </li>
          </ul>
        </p>
        <p>
          <b>12. Termination of Access</b>
          <br />
          <ul>
            <li>
              <b>a. Termination Rights:</b> <br /> swastikgift.online may, at
              its discretion, terminate or restrict your access to the website
              and its services without prior notice for any violation of these
              terms and conditions or for any other reason.
            </li>
          </ul>
        </p>
        <p>
          <b>13. Third-Party Links</b>
          <br />
          <ul>
            <li>
              {" "}
              <b> a. External Websites:</b>
              <br /> swastikgift.online may contain links to third-party
              websites for your convenience. These websites are not under our
              control, and we are not responsible for their content, privacy
              practices, or terms and conditions. Please exercise caution when
              visiting external links.
            </li>
          </ul>
        </p>
        <p>
          <b>14. Privacy and Security</b>
          <br />
          <ul>
            <li>
              {" "}
              <b> a. Privacy Policy:</b> <br />
              Our Privacy & Security Policy outlines how we collect, use, and
              protect your personal information. By using our services, you
              consent to the practices described in this policy.
            </li>
          </ul>
        </p>
        <p>
          <b>15. Limitation of Liability</b>
          <br />
          <ul>
            <li>
              {" "}
              <b> a. No Consequential Damages:</b>
              <br /> swastikgift.online shall not be liable for any indirect,
              incidental, special, consequential, or punitive damages, or any
              loss of profits or revenues, whether incurred directly or
              indirectly, arising out of your use or inability to use our
              services, even if we have been advised of the possibility of such
              damages.
            </li>
          </ul>
        </p>
        <p>
          <b>16. Indemnification</b>
          <br />
          <ul>
            <li>
              {" "}
              <b> a. Your Responsibility:</b>
              <br /> You agree to indemnify and hold swastikgift.online, its
              affiliates, partners, and employees harmless from any claims,
              losses, damages, liabilities, and expenses (including legal fees)
              arising from your use of our services or any violation of these
              terms and conditions.
            </li>
          </ul>
        </p>
        <p>
          <b>17. Contact Information</b>
          <br />
          <ul>
            <li>
              {" "}
              <b> a. Communication:</b>
              <br /> If you have questions, concerns, or need assistance, you
              can contact us through the contact information provided on the
              swastikgift.online website.
            </li>
          </ul>
        </p>
        <p>
          <b>18. Changes to Terms and Conditions</b>
          <br />
          <ul>
            <li>
              {" "}
              <b> a. Updates:</b>
              <br /> swastikgift.online reserves the right to make changes to
              these terms and conditions at any time without prior notice. It is
              your responsibility to review these terms periodically for
              updates. By continuing to use swastikgift.online, you acknowledge
              and agree to these terms and conditions, including any future
              revisions. If you do not accept these terms and conditions, please
              discontinue using our services. Your continued use of our services
              will be deemed as acceptance of any updated terms and conditions.
            </li>
          </ul>
        </p>
        <p>
          <b>19. Governing Law and Dispute Resolution</b>
          <br />
          <ul>
            <li>
              {" "}
              <b> a. Governing Law:</b>
              <br /> These terms and conditions are governed by the laws of
              India, and any disputes or claims shall be subject to the
              exclusive jurisdiction of the courts in Delhi/New Delhi.
            </li>
          </ul>
        </p>
        <p>
          swastikgift.online is committed to providing a convenient and secure
          online shopping experience for our users. By using our services, you
          agree to abide by the terms and conditions outlined above. We value
          your trust and privacy, and our Privacy & Security Policy details how
          we protect your personal information. Please take the time to review
          these terms and conditions, as they govern your interactions with
          swastikgift.online. We reserve the right to modify these terms at any
          time, and your continued use of our website constitutes acceptance of
          any changes made. Should you have any questions, concerns, or require
          assistance, our dedicated team is available to assist you. We
          appreciate your trust in swastikgift.online and look forward to
          serving you with high-quality products and excellent customer service.
          Thank you for choosing swastikgift.online for your online shopping
          needs.
        </p>
      </div>
    </div>
  );
}

export default Termsconditiion;
