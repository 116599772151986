import MainRouting from './Routes/MainRouting';
// import { BrowserRouter as Router } from 'react-router-dom';
import './App.css'
import StickyButton from './component/Homepage/stickybutton/StickyButton';

function App() {
  return (
    <>
     <MainRouting/>
      <StickyButton/>
      

    </>
  );
}

export default App;
