import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './allproduct.css'
import ProductCard from '../ProductCard/ProductCard';



function AllProduct() {

  const [apiData1, setApiData1] = useState([]);
  // const [apiData2, setApiData2] = useState([]);
  const [loading, setLoading] = useState(true);


  const fetchData = ()=>{
    axios.get('https://capi.mini.store/api/v1/stores/swastik-gift/catalog')
  .then((response) => {
    setApiData1(response.data.data.products);
    // setApiData2(response.data.data);
    setLoading(false);
  })
  .catch((error) => {
    console.error('Error fetching data:', error);
    setLoading(false);
  });
  } 
  

  
  // const {fetchData,loading,apiData1} = useData();
  // console.log(fetchData)
  useEffect(()=>{
    fetchData()
  },[])


  if (loading) {
    return <div className='allproduct-container'>
      <h1 className='heading'>
      Loading...
      </h1>
      </div>;
  }
  // const apiData1 =[]


  // console.log(products)

  return (
    <div className='allproduct-container'>
        <h1 className='heading'>All Product</h1>
            <div className="featureproduct-wrapper">
            {apiData1 && apiData1?.map((product) => (
              <ProductCard product={product} linkto={`${product.id}`}/>
          
        ))}
   </div>
    </div>

  )
}

export default AllProduct