import React from "react";
import "./aboutus.css";

function AboutUs() {
  return (
    <div className="aboutuscontainer">
      <h1 className="heading">About Us</h1>
      <h4>Welcome to swastikgift.online – Divine Gifts for Your Home!</h4>
      <div className="aboutuscontent">
        <p>
          At swastikgift.online, we are more than just an online store. We are
          your trusted companion on a spiritual journey that transcends time and
          space. Our mission is simple yet profound – to enrich your sacred path
          with a diverse array of meticulously crafted spiritual products.
        </p>
        <p className='aboutcontentpara'>
  <b>🌟 Elevating Your Spiritual Experiences 🌟</b>
  <br />
  At SwastikGift.online, our mission is to raise the bar for product design and originality, dedicated solely to elevating your spiritual experiences to new heights. Our extensive collection of spiritual products is carefully curated to serve as the guiding light on your sacred journey.
</p>

        <p className='aboutcontentpara'>
          <b>🕯️ Illuminating the Way 🕯️</b>
          <br />
          Whether you seek the radiance of crystals, the presence of divine
          idols, the tranquility of incense, or the illuminating warmth of
          candles, our offerings are a testament to our commitment to amplifying
          your pooja rituals and spiritual practices.
        </p>
        <p className='aboutcontentpara'>
          <b>🎨 Artistry Meets Spirituality 🎨</b>
          <br />
          From exquisitely designed Pooja Boxes to intricately crafted
          Miniatures, MDF LED Frames, Car LED Frames, and mesmerizing Neon MDF
          Frames, each product at swastikgift.online is a work of art infused
          with spirituality.
        </p>
        <p className='aboutcontentpara'>
          <b>✨ Embark on a Profound Journey ✨</b>
          <br />
          We invite you to embark on a profound and meaningful spiritual odyssey with Swastik's exceptional products, exclusively designed to enrich your spiritual journey. Here, you'll find not just products but the tools to deepen your connection with your faith and beliefs.
        </p>
        <p className='aboutcontentpara'>
          <b>🏡 Divine Gifts for Your Home 🏡</b>
          <br />
          Our tagline, "Divine Gifts for Your Home," reflects our commitment to enhancing your living space with sacred items that bring peace and positivity.
        </p>
        <p className='aboutcontentpara'>
          <b>🙏 Explore, Experience, and Enlighten 🙏</b>
          <br />
          Our online store is your gateway to a world of spiritual wonder. Explore our diverse offerings, experience the beauty of spirituality through our products, and let them enlighten your heart and soul. Join us on this transformative journey as we help you create sacred moments and memories that will last a lifetime.
        </p>
        <p className='aboutcontentpara'>
          <b>🛒 Start Your Spiritual Odyssey Today 🛒</b>
          <br />
          Discover the magic of swastikgift.online and make each step of your spiritual journey a sacred and unforgettable one. Start exploring our collection now!
        </p>
        <p>
          swastikgift.online is more than just an online store; it's your spiritual companion, your source of divine inspiration, and the place where your sacred journey truly begins. Our commitment to elevating your spiritual experiences, combined with our extensive collection of meticulously crafted products, reflects our dedication to enriching your path with beauty, tranquility, and positivity. With "Divine Gifts for Your Home" as our guiding philosophy, we aim to transform your living spaces into sanctuaries of serenity and spiritual connection. Each item in our collection is not just a product but a symbol of the spiritual odyssey we invite you to embark upon. Explore, experience, and enlighten your heart and soul with swastikgift.online. Join us in creating sacred moments and lasting memories that will accompany you on your journey of faith and self-discovery. Start your spiritual odyssey today, and let us be a part of your profound and meaningful path. Thank you for choosing us as your partner on this remarkable journey.
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
