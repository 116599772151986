import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo1 from '../../assets/logos/Swastik_ubg.png';

function Navbar() {
  const [isSolidNav, setIsSolidNav] = useState(false);
  const [activeLink, setActiveLink] = useState('/');
  const [toggle, setToggle] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const height = document.querySelector('.navbar').clientHeight;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop >= height - 40) {
        setIsSolidNav(true);
      } else {
        setIsSolidNav(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const clicked = () => setToggle(!toggle);
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  return (
    <nav
      className={`navbar navbar-expand-lg ${
        toggle
          ? isHomePage
            ? isSolidNav
              ? 'navbar-light bg-white'
              : 'navbar-dark bg-transparent'
            : 'navbar-light bg-white'
          : 'navbar-light bg-white'
      }`}
      style={{ position: 'fixed', zIndex: '1000', width: '100%' }}
    >
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img
            src={logo1}
            alt=""
            width="120"
            height="120"
            className="d-inline-block align-text-top"
          />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={clicked}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <Link
              className={`nav-link ${activeLink === '/' ? 'active' : ''}`}
              to="/"
              onClick={!toggle}
            >
              Home
            </Link>
            <Link
              className={`nav-link ${
                activeLink === '/allproduct' ? 'active' : ''
              }`}
              to="/allproduct"
              onClick={!toggle}
            >
              Shop All
            </Link>
            <Link
              className={`nav-link ${
                activeLink ===
                '/categories/6e3ed142-0188-4918-b11c-1ef0295783b3'
                  ? 'active'
                  : ''
              }`}
              to="/categories/6e3ed142-0188-4918-b11c-1ef0295783b3"
              onClick={!toggle}
            >
              Car LED Light
            </Link>
            <Link
              className={`nav-link ${
                activeLink ===
                '/categories/ca85f749-be89-4387-875c-86bd9ee38a47'
                  ? 'active'
                  : ''
              }`}
              to="/categories/ca85f749-be89-4387-875c-86bd9ee38a47"
              onClick={!toggle}
            >
              Pocket Temple
            </Link>
            <Link
              className={`nav-link ${
                activeLink ===
                '/categories/17e1dba0-545c-4064-be5b-ba829aa2fc2f'
                  ? 'active'
                  : ''
              }`}
              to="/categories/17e1dba0-545c-4064-be5b-ba829aa2fc2f"
              onClick={!toggle}
            >
              Antique God Ideol
            </Link>
            <Link
              className={`nav-link ${
                activeLink ===
                '/categories/c1882e63-6f7e-4cdd-85b0-54cbf42a8e07'
                  ? 'active'
                  : ''
              }`}
              to="/categories/c1882e63-6f7e-4cdd-85b0-54cbf42a8e07"
              onClick={!toggle}
            >
              Neon MDF Frame
            </Link>
            <Link
              className={`nav-link ${
                activeLink ===
                '/categories/4891caf5-128a-4c6d-b99c-0e82964eca89'
                  ? 'active'
                  : ''
              }`}
              to="/categories/4891caf5-128a-4c6d-b99c-0e82964eca89"
              onClick={!toggle}
            >
              Evil Eye Nazar Suraksha Hanging Decor
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
