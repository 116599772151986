import React from 'react'
import './privacypolicy.css'

function PrivacyPolicy() {
  return (
    <div className='privacycontainer'>
    <h1 className='heading'>Privacy & Security Policy</h1>
    <div className="privacycontent">

<p>Privacy & Security Policy PAYMENT, DELIVERY & RETURNS At SwastikGift.online, we do not accept returns of items sold under promotion or stock clearance. The product description page will clearly state that such items cannot be returned, unless they arrive in a damaged condition or are defective.
</p>
 <p>
By visiting us at SwastikGift.online, you accept these terms and conditions. Please read them carefully. In addition, when you use any current or future SwastikGift.online service (e.g., Your Account/Profile, Gift Certificates, and Your Reminder Service, etc.) or visit or purchase from any business affiliated with SwastikGift.online, whether or not included in the SwastikGift.online website, you will be subject to these guidelines and conditions for such service or business.
</p><p>
These terms and conditions supersede all previous representations, understandings, or agreements and shall prevail notwithstanding any variance with any other terms of any order placed or service provided by SwastikGift.online. By using the shopping services of SwastikGift.online you agree to be bound by these terms and conditions. All products/services and information displayed on SwastikGift.online constitute an "invitation to offer". Your order for purchase constitutes your "offer" which shall be subject to the terms and conditions as listed below. SwastikGift.online reserves the right to accept or reject your offer.
</p><p>
<b>Applicable Law : </b>
This site is created and controlled by SwastikGift.online. The laws of India shall apply and courts in Delhi/New Delhi shall have jurisdiction in respect of all the terms, conditions and disclaimers. SwastikGift.online reserves the right to make changes to the website and the terms, conditions and disclaimers at any time and without information to the customers/ users of the services/ website of SwastikGift.online.
</p><p>
Definitions <b>"Agreement"</b> means the terms and conditions as detailed herein including all schedules, appendices, annexures, privacy policy, and will include the references to this agreement as amended, negated, supplemented, varied or replaced from time to time.
</p><p>
<b>swastikgift.online</b> / <b>Swastik Gift</b>  / "website" means the online shopping platform and the services provided by it and its affiliates owned and operated by Firki Wholesale Private Limited which provides a venue / platform to the Users of SwastikGift to buy the products listed on SwastikGift.online.
</p><p>
<b>Vendor / seller / affiliates</b> shall mean the person or any legal entity who offers for sale, sells the products on the SwastikGift platform / website. ''Product/Products'' means and includes any goods/ merchandise/ products/ services/ offers/ display items that are uploaded /showcased/displayed on SwastikGift and the related description, information, procedure, processes, warranties, delivery schedule, etc.
</p><p>
Electronic Communication
When you visit SwastikGift or send e-mails to us or give us your feedback, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by e-mail or by posting notices on this website. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.
</p><p>
Disclaimer of Warranties & Limitation of Liability
SwastikGift has made this service available to use as a matter of convenience. SwastikGift expressly disclaims any claim or liability arising out of uploading of any obscene, vulgar or pornographic images, photograph or a picture or altering or distorting the images available under this service in an obscene, vulgar or pornographic manner. SwastikGift also disclaims all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose. SwastikGift does not warrant that this site, its servers, or e-mail sent from SwastikGift are free of viruses or other harmful components."</p>
</div>
 </div>
  )
}

export default PrivacyPolicy