import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from '../component/Navbar/Navbar';
import Homepage from '../component/Homepage/Homepage';

import Footer from '../component/Footer/Footer';
import AllProduct from '../component/AllProduct/AllProduct';
// import ProductDetail from '../component/AllProduct/ProductDetail/Productdetail';
import SingleProduct from '../component/AllProduct/DetailsProduct/SingleProduct';
import Category from '../component/Homepage/BestSeller/category/Category';
import PrivacyPolicy from '../component/Footer/pages/PrivacyPolicy/PrivacyPolicy';
import Termsconditiion from '../component/Footer/pages/Terms/Termsconditiion';
import Career from '../component/Footer/pages/career/Career';
import Contactus from '../component/Footer/pages/contactus/Contactus';
import AboutUs from '../component/Footer/pages/AboutUS/AboutUs';

function MainRouting() {
  return (
    <Router>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Homepage/>} />
        <Route path= '/aboutus' element={<AboutUs/>}/>
        <Route path='/allproduct' element={<AllProduct/>}/>
        <Route path='/allproduct/:id' element={<SingleProduct/>}/>
        <Route path='/categories/:id' element={<Category/>}/>
        <Route path='/Privacypolicy' element = {<PrivacyPolicy/>}/>
        <Route path='/term&conditions' element={<Termsconditiion/>}/>
        <Route path='/career' element={<Career/>}/>
        <Route path='/contactus' element={<Contactus/>}/>
      </Routes>
      <Footer />
    </Router>
  );
}

export default MainRouting;
