import React from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import "./productdetail.css"
import ProductCard from '../../ProductCard/ProductCard';

function SingleProduct() {
    const { id } = useParams();
    const [com, setCom] = useState('');
    const [similardata, setSimilardata]= useState([]);
    const [apiData1, setApiData1] = useState([]);
    // const [apiData2, setApiData2] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedImage, setSelectedImage] = useState('');
    
    const handleImageClick = (image) => {
      setSelectedImage(image);
    };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://capi.mini.store/api/v1/stores/swastik-gift/catalog');
        setApiData1(response.data.data.products);
        // console.log('hello world')
        // console.log(response.data.data.products)
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  },[id]);

  const data = apiData1.find((item) => item.id === (id));
  const similarProductData = apiData1.filter((item) => item.categories[0] === data.categories[0]);
// console.log(similarProductData);


  // console.log(data)
  useEffect(() => {
    // console.log(data)
    setCom(data);
    setSimilardata(similarProductData);
    // console.log(similardata);
    if(data){
      setSelectedImage(`https://minis-media-assets.swiggy.com/swiggymini/image/upload/w_1024,h_1024,c_fit,q_auto,f_auto/${data.images[0]}`)
    }
  },[data]);    

    
    //     // setCom(data)
      
    if (loading) {
      <div className='allproduct-container'>
      <h1 className='heading'>
      Loading...
      </h1>
      </div>;
    }
  
    if (!com) {
      return <div className='allproduct-container'>
      <h1 className='heading'>Product not found</h1></div>;
    }
    
      
    //   console.log(com)
    //   console.log(id)

  
 
    
    const currentHostname = window.location.hostname;
const currentPort = window.location.port;

const baseUrl = `http://${currentHostname}:${currentPort}`;




const phoneNumber = '9415984308'; // Replace with the recipient's phone number
const productlink = `https://swastik-gift.mini.store/products/${com.id}?slug=swastik-gift`
const message = `Hello, I want to know about this product - ${productlink} `;


// Encode the phone number and message
const encodedPhoneNumber = encodeURIComponent(phoneNumber);
const encodedMessage = encodeURIComponent(message);

// Create the WhatsApp link
const whatsappLink = `https://api.whatsapp.com/send?phone=${encodedPhoneNumber}&text=${encodedMessage}`;


  return (
    <div className='singleproduct-container'>
        <div className="product">
        <h3 >{com.name}</h3>
        <div className="product-item">
        <div className="product-thumbnails">
        {com?.images.map((image, index) => (

            <div className="image-preview" >
            <img src={`https://minis-media-assets.swiggy.com/swiggymini/image/upload/w_1024,h_1024,c_fit,q_auto,f_auto/${image}`} alt="Product Preview" onClick={()=>
             handleImageClick(`https://minis-media-assets.swiggy.com/swiggymini/image/upload/w_1024,h_1024,c_fit,q_auto,f_auto/${image}`)}   />
            </div>
          
        ))}
      </div>
      <div className="product-image">
        <img src={selectedImage} alt={com.name} />
      </div>
      <div className="productdetails">
      {/* <h3>{com.name}</h3> */}
      <p>{com.description}</p>
      <p style={{color:"black", fontWeight:'600'}} >₹ {com.discountedPrice} <span style={{color:"#f28121"}}>({com.discountPercent}% Off)</span></p>
              <p style={{color: "rgb(102, 104, 110)", fontSize: "13px",  lineHeight: "16px", letterSpacing: "-0.02px",textDecorationLine: "line-through"}} >₹ {com.price}</p>
              <div className="productLink">
              <Link to = {`https://swastik-gift.mini.store/products/${com.id}`} target='_blank' type="submit" >Buy Now!</Link>
              <Link to = {whatsappLink} target='_blank' type="submit" >Want COD?</Link>
              </div>
              <div className="productpayment">
              <p>COD Avaliable</p>
              <p>online payment patterns -  Minis on Swiggy</p>
              <p>Free shipping all over India</p>
              </div>
      </div>
      
    </div>
        </div>

        <div className="similarProduct">
          <h1 className='heading'> Similar Products</h1>
          <div className="featureproduct-wrapper">

          {similardata?.map((product) => (
              <ProductCard product={product} linkto={`${baseUrl}/allproduct/${product.id}`}/>
          
        ))}


          </div>
        </div>
        
    </div>
  );
}

export default SingleProduct;
