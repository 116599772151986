import React from 'react'
import "./FeatureProduct.css"
import ProductCard from '../../ProductCard/ProductCard';
import { data } from './featuredata';

const currentHostname = window.location.hostname;
const currentPort = window.location.port;

const baseUrl = `http://${currentHostname}:${currentPort}`;
function FeatureProduct() {
  return (

        <div className="featureproduct-container">
            <h1 className='heading'>Feature Products</h1>
            <div className="featureproduct-wrapper">
            {data.products.map((product) => (
          <ProductCard key={product.id} product={product} linkto={`${baseUrl}/allproduct/${product.id}`} />
        ))}
                        </div>
    </div>
  )
}

export default FeatureProduct