import React from 'react'
import "./stickybutton.css"
import { Link } from 'react-router-dom'

function StickyButton() {
  return (
    <div id="mybutton">
<button class="feedback"><a href="https://wa.me/9415984308?text=I'm%20interested%20in%20your%20products."><i className="fa fa-whatsapp"></i></a></button>
</div>
  )
}

export default StickyButton