import React from 'react'
import Herosection from './Herosection/Herosection'
import Knowus from './KnowUs/Knowus'
import FeatureProduct from './FeatureProduct/FeatureProduct'
import Parallex from './Parallex/Parallex'
import BestSeller from './BestSeller/BestSeller'

function Homepage() {
  return (
    <>
    <Herosection/>
    <Knowus/>
    <BestSeller/>
    <Parallex/>
    <FeatureProduct/>
    </>
  )
}

export default Homepage