
import React from 'react'
import './Contactus.css'
function Contactus() {
  return (
    <div className="contactcontainer">
        <div className="contactcontent">  
    <h1 className='heading'>Contact Us</h1>
    <p>We're here to assist you. Feel free to reach out through the following channels:</p>
    <div className="contact-links">
        <a href="mailto:giftswastik@gmail.com" className="contact-link"><i className="fa fa-envelope"></i> Email Us</a>
        <a href="tel:+91 9415984308" className="contact-link"><i className="fa fa-phone"></i> Call Us</a>
        <a href="https://www.facebook.com/profile.php?id=61550673964437&mibextid=ZbWKwL" className="contact-link" target="_blank"><i className="fa fa-facebook"></i> Visit Our Facebook</a>
        <a href="https://www.instagram.com/swastik_gift" className="contact-link" target="_blank"><i className="fa fa-instagram"></i> Follow Us on Instagram</a>
        <a href="https://wa.me/9415984308?text=I'm%20interested%20in%20your%20products." className="contact-link" target="_blank"><i className="fa fa-whatsapp"></i> Clear your doubts on WhatsApp</a>
    </div>
    
    </div>
</div>
  )
}

export default Contactus


