
    export const data= {
      "products": [
        {
          "id": "04ccc4d0-79f8-47c6-b569-2b43b41d3af9",
          "storeId": "92b849f5-7137-4aac-9b1a-443c5c2b1636",
          "name": "GANESH JI CAR LED LIGHT DASHBOARD",
          "description": "Car LED Light\n\n Acrylic Ganesh ji Car LED Light For Car for religious, Home, ( 10 x 12) centimetres, Ideal gift on the occasion of marriage, birthday, corporate gifts, that represents our culture, which gives positive energy, and bring our children's and friend's towards our great cultural heritage of thousands of years",
          "weight": 0.25,
          "price": 1000,
          "discountedPrice": 599,
          "discountPercent": 41,
          "images": [
            "IMAGE/7523f20f-f21e-4e9b-93b9-eb39f16bbb8f/TcD5-HwOywZmGJYB7i2Y9",
            "IMAGE/67d9d019-2d7d-4ddb-9231-49e92dacc6dd/u5GjUsNkvKHiH1obMervU"
          ],
          "badges": [
            
          ],
          "categories": [
            "6e3ed142-0188-4918-b11c-1ef0295783b3"
          ],
          "active": true,
          "inStock": true,
          "createdAt": 1694464619,
          "updatedAt": 1694469573,
          "storeBadges": [
            
          ]
        },
        {
          "id": "05e3c0de-5249-4c8b-8da3-e3d9d2c678b5",
          "storeId": "92b849f5-7137-4aac-9b1a-443c5c2b1636",
          "name": "Nav Durga Pocket Temple Small Size(24K Gold Coated)",
          "description": "This pocket temple is plated with pure gold, making it a luxurious and sacred addition to your daily spiritual practice. The temple features nine forms of Nav Durga – Shailputri, Brahmacharini, Chandraghanta, Kushmanda, Skandamata, Katyayani, Kalaratri, Mahagauri, and Siddhidatri. Each intricately carved and finished with intricate details.",
          "weight": 0.35,
          "price": 1000,
          "discountedPrice": 699,
          "discountPercent": 31,
          "images": [
            "IMAGE/ec4a2c82-9667-458d-b01f-b7b3499fd8d6/dCJ24790oXgLksgofUibu",
            "IMAGE/381776b8-24c0-43f3-851e-4de7db6bd7d1/UXw6NncTE5F-TmyQZbp4o"
          ],
          "badges": [
            
          ],
          "categories": [
            "ca85f749-be89-4387-875c-86bd9ee38a47"
          ],
          "active": true,
          "inStock": true,
          "createdAt": 1694443828,
          "updatedAt": 1694443828,
          "storeBadges": [
            
          ]
        },
       
       
        {
          "id": "3c5ad9de-f5af-4c80-98cc-753c0edd86b2",
          "storeId": "92b849f5-7137-4aac-9b1a-443c5c2b1636",
          "name": "Golden Temple Pocket Temple Small Size(24K Gold Coated)",
          "description": "This pocket temple holds of great Sikh importance with the gold foil-covered Golden temple since it is the most sacred pilgrimage spot for the sikhs. So get this prestigious item to enhance the spiritual vibe in your abode",
          "weight": 0.25,
          "price": 1000,
          "discountedPrice": 699,
          "discountPercent": 31,
          "images": [
            "IMAGE/65320d66-6afc-45f7-8f19-63d899e3efd2/ko3L9p3HVdYhAhFQEKjAo",
            "IMAGE/ae6600cc-021f-4b82-b67f-7efc439a157b/MYy7PpYf1hdcKURpweevb"
          ],
          "badges": [
            
          ],
          "categories": [
            "ca85f749-be89-4387-875c-86bd9ee38a47"
          ],
          "active": true,
          "inStock": true,
          "createdAt": 1694442968,
          "updatedAt": 1694443142,
          "storeBadges": [
            
          ]
        },
        
        
        {
          "id": "488c84b0-a111-428f-b5ec-b729720da447",
          "storeId": "92b849f5-7137-4aac-9b1a-443c5c2b1636",
          "name": "RAM MANDIR NEON MDF FRAME",
          "description": "Exclusively design MDF wood led frame for the embellishment of your home. Useful and attractive this led lamp comes along unique prefixed stand. The led frame lamp is made of good-quality mdf wood with laminate finish. Its unique design will add a charisma to your home. It is high in quality and will harmonize your home interiors. It signifies the traditional and ethnic skill of India. Bring an element of royalty to your home. Details: Handcrafted piece of art complements your home interiors. Your home, your office or your business venture will reverberate with the rich, ethnicity of India’s heritage. Gift the uniquely designed led frame to your loved one, and glee them with your inventive taste. Take your pick from our richest assortment of handicrafts and give your home a unique individuality\n\nSize - 18*24\nSize -  12*18",
          "weight": 0.75,
          "price": 1499,
          "discountedPrice": 899,
          "discountPercent": 40,
          "images": [
            "IMAGE/a2084c09-f80f-488b-8d7a-4ab5a9884f3a/Sty0KkQ4IdxMGJRce1JoD",
            "IMAGE/5de12df0-d0b8-4fee-b909-d14443b2c46f/Tn3eoNkIttsLOinlqO2Im",
            "IMAGE/f75cc84e-8f59-479a-bc28-1b53280e9611/Z0ZX56Ine54WlFHykteCG",
            "IMAGE/3f52f96f-5581-469c-b621-4b71716c3e52/rc-oTIxFovyeeZLjndnDx"
          ],
          "badges": [
            
          ],
          "categories": [
            "c1882e63-6f7e-4cdd-85b0-54cbf42a8e07"
          ],
          "active": true,
          "inStock": true,
          "createdAt": 1694467190,
          "updatedAt": 1694508697,
          "storeBadges": [
            
          ]
        },
        {
          "id": "4cc1a427-e916-48f9-a462-d9a2811b754e",
          "storeId": "92b849f5-7137-4aac-9b1a-443c5c2b1636",
          "name": "LORD BUDDHA MDF NEON FRAME",
          "description": "LORD BUDDHA MDF \n\nFRAMEExclusively design MDF wood led frame for the embellishment of your home. Useful and attractive this led lamp comes along unique prefixed stand. The led frame lamp is made of good-quality mdf wood with laminate finish. Its unique design will add a charisma to your home. It is high in quality and will harmonize your home interiors. It signifies the traditional and ethnic skill of India. Bring an element of royalty to your home. Details: Handcrafted piece of art complements your home interiors. Your home, your office or your business venture will reverberate with the rich, ethnicity of India’s heritage. Gift the uniquely designed led frame to your loved one, and glee them with your inventive taste. Take your pick from our richest assortment of handicrafts and give your home a unique individuality.\n\nSize - 18*24\nSize -  12*18",
          "weight": 0.75,
          "price": 1499,
          "discountedPrice": 899,
          "discountPercent": 40,
          "images": [
            "IMAGE/ffe071e4-8234-4d1e-aa87-ca77226d9680/YQGKAI8HjC-5ok69DLpuu",
            "IMAGE/3c9a1f76-0f12-4f85-82f7-4bb9e15e4e0a/mnHRDKoDPjDxMheHW6l0H",
            "IMAGE/aada92f7-c6a0-4b17-8e49-fb4002fcc614/ZV-61dHvJBI4s3y9OfLlw"
          ],
          "badges": [
            
          ],
          "categories": [
            "c1882e63-6f7e-4cdd-85b0-54cbf42a8e07"
          ],
          "active": true,
          "inStock": true,
          "createdAt": 1694468251,
          "updatedAt": 1694509096,
          "storeBadges": [
            
          ]
        },
        {
          "id": "5551cb4b-d846-40a3-b8b9-9ffa853aa713",
          "storeId": "92b849f5-7137-4aac-9b1a-443c5c2b1636",
          "name": "Ram Darbar Pocket Temple Large Size(24K Gold Coated)",
          "description": "Bless your family and home with the auspicious presence of holy trinity of knowledge, wealth & prosperity which comes home in the form of Saraswati, Laxmi and Ganesha in this exquisite wooden finish pocket temple wrapped in 24KT gold.\n\nThe 3 idols have been engraved onto the top half of the box and have been finely plated in pure 24 Karat Gold. It even comes with a gold foil wrapped “Shubh Labh” message that will bless your home and protect it from all evil.\n\nIt’s an auspicious & alluring addition to your altar, locker, puja room, or any religious setting. A great exchange of good wishes for the festive season! It’s our best choice product for “Diwali Gifts”\n\nMaterial: Sandalwood, Gold & Silver Plated\n\n\nDimension: (21 x 12 x 5 cms) / (8.2 x 4.7 x 2 inches)\n\nWeight: 350 grams\n\nMaterial: Outer box is made of Polyresin & has Sandalwood fragrance sticks. The inner contents are made of copper, which are coated in 24 Karat Gold and 999 Silver. The Pocket temple comes in a very elegant yet premium outer box which would enhance your gifting experience",
          "weight": 0.35,
          "price": 1200,
          "discountedPrice": 799,
          "discountPercent": 34,
          "images": [
            "IMAGE/d455c0f6-31f5-4b6d-9b30-8ca88f27a837/0XozEqQPcs-ejNKKIQLfA",
            "IMAGE/29accb5f-e8db-4dbb-8a5e-0387cce64e7a/bTYPs4dpj26T-m1N0lxsK",
            "IMAGE/c02e17ca-6499-4eaa-b8a7-e8f303d28cce/93G3fTEMJDpJaYxJXomXa",
            "IMAGE/2500aaa4-1d62-4666-a723-93000859e192/w320jUX8vKpCDfs8Yrvyq",
            "IMAGE/05bbe159-c30b-4fee-99d5-aa166a978f78/y9Lb6s9MF3-ajXJD098JV"
          ],
          "badges": [
            
          ],
          "categories": [
            "ca85f749-be89-4387-875c-86bd9ee38a47"
          ],
          "active": true,
          "inStock": true,
          "createdAt": 1694436910,
          "updatedAt": 1694444484,
          "storeBadges": [
            
          ]
        },
        {
          "id": "631c6512-098e-4857-b872-1251e814ef21",
          "storeId": "92b849f5-7137-4aac-9b1a-443c5c2b1636",
          "name": "RADHE KRISHNA NEON MDF FRAME",
          "description": "Exclusively design MDF wood led frame for the embellishment of your home. Useful and attractive this led lamp comes along unique prefixed stand. The led frame lamp is made of good-quality mdf wood with laminate finish. Its unique design will add a charisma to your home. It is high in quality and will harmonize your home interiors. It signifies the traditional and ethnic skill of India. Bring an element of royalty to your home. Details: Handcrafted piece of art complements your home interiors. Your home, your office or your business venture will reverberate with the rich, ethnicity of India’s heritage. Gift the uniquely designed led frame to your loved one, and glee them with your inventive taste. Take your pick from our richest assortment of handicrafts and give your home a unique individuality\n\nSize - 18*24\nSize -  12*18",
          "weight": 0.75,
          "price": 1499,
          "discountedPrice": 899,
          "discountPercent": 40,
          "images": [
            "IMAGE/8bbca1d4-acee-4ba2-81db-171c32d4b024/rtn0hR4MeENNb7Xt5s8Jk",
            "IMAGE/6d09db77-8e01-4668-99bb-c31b20bbf388/Hr9P-NISnFKCbQELgn2L9",
            "IMAGE/c5cb2eb6-4409-42ce-b8b9-915658ed330b/QeZfDdy1t5wJjclNbz-Q5",
            "IMAGE/7839e857-94dc-4930-ade3-51cbe0918bf5/Bprj9lqkZZNGQUGCnUkZv"
          ],
          "badges": [
            
          ],
          "categories": [
            "c1882e63-6f7e-4cdd-85b0-54cbf42a8e07"
          ],
          "active": true,
          "inStock": true,
          "createdAt": 1694467523,
          "updatedAt": 1694469523,
          "storeBadges": [
            
          ]
        },
       
       
        {
          "id": "9e77de4f-af73-4544-84a6-c1df2ab3d648",
          "storeId": "92b849f5-7137-4aac-9b1a-443c5c2b1636",
          "name": "5-Horse Evil Eye Nazar Suraksha Hanging Decor for Home Protection",
          "description": "Experience the enchanting fusion of artistry and spirituality with our 5 Horse Evil Eye Nazar Suraksha Hanging Decor. Crafted with meticulous attention to detail, this unique piece combines ancient Indian traditions with contemporary design.\n\nEach of the five majestic horse figurines represents strength, grace, and resilience. Adorned with vibrant evil eye amulets, they serve as powerful talismans against negative energies, attracting positivity and protection.\n\nHandcrafted to perfection, this decor piece is a testament to exquisite craftsmanship. From the delicately painted horses to the captivating blue evil eye amulets, every detail showcases the rich cultural heritage of India.\n\nHang this decor in your living space, near entrances or areas where negativity lingers. As it sways gently, it creates an ambiance of harmony and tranquility, shielding your home from the malevolent gaze of the evil eye",
          "price": 800,
          "discountedPrice": 549,
          "discountPercent": 32,
          "images": [
            "IMAGE/44498fe5-d7ac-493f-9530-54ad44c3bf18/IxV6aMtN5U68UCGalFbSn",
            "IMAGE/fd0c2073-534d-4e2a-b767-af6998f19752/kLRqr2EDmDcJMBi3hrPmE",
            "IMAGE/45bc0723-d87c-4b34-917e-cb8008b48478/t9GV3IOEP9EqiC-uAmBk4"
          ],
          "badges": [
            
          ],
          "categories": [
            "4891caf5-128a-4c6d-b99c-0e82964eca89"
          ],
          "active": true,
          "inStock": true,
          "createdAt": 1694510045,
          "updatedAt": 1694510045,
          "storeBadges": [
            
          ]
        },
       
      
        {
          "id": "ca100bf3-e4e8-4055-946e-857ad9428af8",
          "storeId": "92b849f5-7137-4aac-9b1a-443c5c2b1636",
          "name": "Khatu Shyam ji face Car LED Light",
          "description": "Khatu Shyam ji face Car LED Light\n\n Acrylic Khatu Shyam ji Car LED Light For Car for religious, Home, ( 10 x 12) centimetres, Ideal gift on the occasion of marriage, birthday, corporate gifts, that represents our culture, which gives positive energy, and bring our children's and friend's towards our great cultural heritage of thousands of years. Prabu Sri Ram represents ethos of Bharatha, our country is identified by true spiritual gifts bestowed by our ancestors to this world. Our product represents a small piece of true spiritual",
          "weight": 0.25,
          "price": 1200,
          "discountedPrice": 649,
          "discountPercent": 46,
          "images": [
            "IMAGE/2a52c967-f41e-4ae5-91da-99e80936195a/RwtJKdCxT-KfNYkhUi0-T",
            "IMAGE/712cbb99-373d-4559-a4d2-e140f10167f0/PYw2mF6rDmxliqPA8uMyW",
            "IMAGE/2833e785-5bcd-4213-8a65-ad4cba2f3852/gtdAeygWQhQs7Ok8Xn74h",
            "IMAGE/db77c79f-f977-4334-a244-83992a584b94/HwXrsu-S-FMhPHVhl1NNC",
            "IMAGE/ed6cf90c-c8f8-449c-8253-641cf2759290/3u-9909FkyCOpzRH5hqmC"
          ],
          "badges": [
            
          ],
          "categories": [
            "6e3ed142-0188-4918-b11c-1ef0295783b3"
          ],
          "active": true,
          "inStock": true,
          "createdAt": 1694464298,
          "updatedAt": 1694466358,
          "storeBadges": [
            
          ]
        },
       
       
       
        
        {
          "id": "f7d1c513-f038-4c2f-8878-92c4d00d8806",
          "storeId": "92b849f5-7137-4aac-9b1a-443c5c2b1636",
          "name": "Khatu Shyam ji Pocket Temple Small Size(24K Gold Coated)",
          "description": "This beautiful Khatu Shyam Pocket temple coated with pure 24 Karat Gold will not only add on to the holiness of your temple but will glorify the look of your temple. You can also gift this beautiful Pooja box to your friends, family members or clients",
          "weight": 0.25,
          "price": 1000,
          "discountedPrice": 699,
          "discountPercent": 31,
          "images": [
            "IMAGE/62d943f3-e407-4e81-ab58-19c4758ac1db/8TLSKzEe9L6X4XCc5omMO",
            "IMAGE/7ab53750-408f-4247-98d6-60574e026ceb/hcBsQTr-3NAa2kz2uiFqM",
            "IMAGE/4eae0393-f744-409f-beac-dfc0b895ff92/FPSPTmZ04thL35gu21MvZ",
            "IMAGE/f395dc93-7e62-4310-a5ee-0f4ad8ccf026/EGX0X2dy4QtUfMHu9ianx",
            "IMAGE/48928830-f17f-427b-9eb1-aa3d9f5e1b73/Rc99mz7FjsZAEGxeJGL46"
          ],
          "badges": [
            
          ],
          "categories": [
            "ca85f749-be89-4387-875c-86bd9ee38a47"
          ],
          "active": true,
          "inStock": true,
          "createdAt": 1694445539,
          "updatedAt": 1694445552,
          "storeBadges": [
            
          ]
        },
        {
          "id": "f7debd38-bdf6-4dbc-acb0-0f65348bf707",
          "storeId": "92b849f5-7137-4aac-9b1a-443c5c2b1636",
          "name": "Wahe Guru ji Car LED Light",
          "description": "Wahe Guru ji Car LED Light\n\n Acrylic Wahe Guru ji Car LED Light For Car for religious, Home, ( 10 x 12) centimetres, Ideal gift on the occasion of marriage, birthday, corporate gifts, that represents our culture, which gives positive energy, and bring our children's and friend's towards our great cultural heritage of thousands of years",
          "weight": 0.25,
          "price": 1000,
          "discountedPrice": 599,
          "discountPercent": 41,
          "images": [
            "IMAGE/82e6d15e-7e4d-4917-ac9a-88d5dd3fec44/9lsKCN-lBkECmVviVgU1z",
            "IMAGE/cfa6780c-fdfb-4bfe-8be0-4b93bb0f4b9a/UMk72JGufp7WQKr6So-hL",
            "IMAGE/c1b3113f-b53b-4df4-8d17-71468938a252/onPGsyHa-P6Xewzmmyyoj",
            "IMAGE/093e773d-1741-423e-b3af-7299907ea676/bPFxDw-P0aKXrWhN-RjZc",
            "IMAGE/cf732522-aa1a-41a2-b4c2-cf1a2ba4cd21/vfBPvTC9SFGga-BHrqnMW"
          ],
          "badges": [
            
          ],
          "categories": [
            "6e3ed142-0188-4918-b11c-1ef0295783b3"
          ],
          "active": true,
          "inStock": true,
          "createdAt": 1694464782,
          "updatedAt": 1694466445,
          "storeBadges": [
            
          ]
        }
      ],
      
    }
  