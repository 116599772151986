import React from 'react'
import './career.css'
import { Link } from 'react-router-dom'
function Career() {
  return (
    <div className="careercontainer">
        <div className="careercontent">
        <h1>No Openings Available</h1>
        <p>Thank you for your interest in our company. Currently, there are no job openings. Please check back later for future opportunities.</p>
        <Link to="/" class="button">Go Back to Home</Link>
        </div>
    </div>
      
  )
}

export default Career



        

