import React from 'react'
import './herosection.css'

function Herosection() {
  return (
    <div className="hero">
        <div className="overlay"></div>
        <div className="hero-text">
            <h1>Divine Gifts for Your Home</h1>
            <p>• Pooja Box • Miniature • MDF Led Frame • Car Led Frame • Neon MDF Frame •</p>
        </div>
    </div>
  )
}

export default Herosection