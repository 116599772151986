import React, { useState, useEffect } from "react";
import "./bestseller.css";
import axios from "axios";
// import img1 from "../../../assets/imgs/mandir_img.jpg";
import { Link } from "react-router-dom";

function BestSeller() {
  const [apiData1, setApiData1] = useState([]);
  const [apiData2, setApiData2] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    axios
      .get("https://capi.mini.store/api/v1/stores/swastik-gift/catalog")
      .then((response) => {
        setApiData1(response.data.data.products);
        setApiData2(response.data.data.categories);
        // console.log(apiData1);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const arr = [];
  const uniqueCategoryIds = [
    ...new Set(apiData1.map((product) => product.categories[0])),
  ];
  for (let index = 0; index < uniqueCategoryIds.length; index++) {
    const productsWithUniqueCategories1 = apiData1.filter(
      (product) => product.categories[0] === uniqueCategoryIds[index]
    );
    arr[index] = productsWithUniqueCategories1[0];
  }
  // console.log(arr);

  if (loading) {
    <div className="allproduct-container">
      <h1 className="heading">Loading...</h1>
    </div>;
  }
  return (
    <div className="bestseller-container">
      <h1 className="heading" style={{ color: "white" }}>
        Categories
      </h1>
      <div className="bestseller-wrapper">
        <div className="bestsell-row">
          {arr &&
            arr?.map((product) => (
              <div className="bestsell-col" key={product.id}>
                <div className="bestsell-text-wrapper">
                  <img
                    src={`https://minis-media-assets.swiggy.com/swiggymini/image/upload/w_1024,h_1024,c_fit,q_auto,f_auto/${product.images[0]}`}
                    alt=""
                  />

                  {/* {products[0]} */}
                  <h2>
                    {apiData2.map((apiproduct) => {
                      if (apiproduct.id === product.categories[0]) {
                        return apiproduct.name;
                      }
                    })}
                  </h2>
                  <Link
                    to={`/categories/${product.categories[0]}`}
                    type="submit"
                  >
                    &nbsp;View Products&nbsp;
                  </Link>
                  <br />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default BestSeller;
