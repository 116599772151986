import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import './Categories.css'
import axios from 'axios';
import ProductCard from '../../../ProductCard/ProductCard';
function Category() {

    const { id } = useParams();
    const [com, setCom] = useState('');
    const [similardata, setSimilardata]= useState([]);
    const [apiData1, setApiData1] = useState([]);
    const [apiData2, setApiData2] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedImage, setSelectedImage] = useState('');
    
    const handleImageClick = (image) => {
      setSelectedImage(image);
    };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://capi.mini.store/api/v1/stores/swastik-gift/catalog');
        setApiData1(response.data.data.products);
        setApiData2(response.data.data.categories);
        // console.log('hello world')?
        // console.log(response.data.data.products)
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  },[id]);

  const similarProductData = apiData1.filter((item) => item.categories[0] === (id));
  // console.log(similarProductData);
  const data = apiData2.find((item) => item.id === (id));


  // console.log(data)
  useEffect(() => {
    // console.log(data)
    setCom(data);
    setSimilardata(similarProductData);
    // console.log(similardata);
    
  },[data]);    

    
    //     // setCom(data)
      
    if (loading) {
      <div className='allproduct-container'>
      <h1 className='heading'>
      Loading...
      </h1>
      </div>;
    }
  
    if (!similarProductData) {
      return <div className='allproduct-container'>
      <h1 className='heading'>Product not found</h1></div>;
    }
   
    
    const currentHostname = window.location.hostname;
const currentPort = window.location.port;

const baseUrl = `http://${currentHostname}:${currentPort}`;




  return (
    <div className='categories-container'>
    {/* <h1 className='heading'>{data.name}</h1> */}
        <div className="featureproduct-wrapper">
        {similarProductData && similarProductData?.map((product) => (
          <ProductCard product={product} linkto={`${baseUrl}/allproduct/${product.id}`}/>
      
    ))}
                    </div>
</div>

  )
}

export default Category