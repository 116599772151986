import React from 'react';
import { Link } from 'react-router-dom';
import "./productcard.css"

function ProductCard({product,linkto}) {
  return (
    <Link to={linkto} style={{textDecoration:'none'}}>
    <div className="featureproduct-card" key={product.id}>
    <span class="sale">-{product.discountPercent}%</span>
    <img
      src={`https://minis-media-assets.swiggy.com/swiggymini/image/upload/w_1024,h_1024,c_fit,q_auto,f_auto/${product.images[0]}`}
      alt={product.name}
      className="featureproduct-img"
    />
    <div className="featureproduct-text">
      <h2>{product.name}</h2>
      <p style={{ color: "black", fontWeight: '600' }}>₹ {product.discountedPrice} <span style={{ color: "#f28121" }}>({product.discountPercent}% Off)</span></p>
      <p style={{ color: "rgb(102, 104, 110)", fontSize: "13px", lineHeight: "16px", letterSpacing: "-0.02px", textDecorationLine: "line-through" }}>₹ {product.price}</p>
      <Link to={linkto} type="submit">Know More</Link>
    </div>
  </div>
  </Link>
  )
}

export default ProductCard