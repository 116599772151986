import React from 'react'
import "./footer.css"
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer>
<div className="footer">
<div className="footer-row">
<a href="mailto:giftswastik@gmail.com"><i className="fa fa-envelope"></i></a>
<a href="https://www.instagram.com/swastik_gift"><i className="fa fa-instagram"></i></a>
<a href="https://www.facebook.com/profile.php?id=61550673964437&mibextid=ZbWKwL"><i className="fa fa-facebook"></i></a>
<a href="https://wa.me/9415984308?text=I'm%20interested%20in%20your%20products."><i className="fa fa-whatsapp"></i></a>

</div>

<div className="footer-row">
<ul>
<li><Link to='/contactus'>Contact us</Link></li>
<li><Link to='/aboutus'>About Us</Link></li>
<li><Link to="/privacypolicy">Privacy Policy</Link></li>
<li><Link to="/term&conditions">Terms & Conditions</Link></li>
<li><Link to='/career'>Career</Link></li>
</ul>
</div>

<div className="footer-row">
Swastik Gift Copyright © 2023 Swastik Gift - All rights reserved || Designed By: Saurabh Srivastava 
</div>
</div>
</footer>
  )
}

export default Footer