import React from 'react'
import './Knowus.css'

function Knowus() {
  return (
    <>
    <div className="knowus-container">
       <h1 >Swastik Gift</h1> 
       <p> Swastik presents an extensive collection of spiritual products tailored to enhance your sacred journey. Our offerings encompass a diverse array of items, ranging from exquisitely designed Pooja Boxes to intricately crafted Miniatures, MDF LED Frames, Car LED Frames, and mesmerizing Neon MDF Frames. Each product is a testament to our commitment to elevating your spiritual experiences. Whether you seek the radiance of crystals, the presence of divine idols, the tranquility of incense, or the illuminating warmth of candles, we have meticulously curated these offerings to enrich your pooja rituals. Embark on a profound and meaningful spiritual odyssey with Swastik's exceptional products, exclusively designed to amplify your spiritual journey.</p>
    </div>
    </>
  )
}

export default Knowus